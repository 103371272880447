.App {
  width: 100%;
}

.custom-shape-divider-bottom-1662255541 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1662255541 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 34px;
}

.custom-shape-divider-bottom-1662255541 .shape-fill {
  fill: #ffffff;
}
